<template>
  <div>
    <div style="position: relative;">
      <van-row>
        <van-col span="24" class="mcenter">
          <van-image :src="require('@/assets/banner.png')" height="92vh" width="98vw"></van-image>
        </van-col>
      </van-row>
      <van-row>
        <van-col span="24" class="mcenter">
          <p>成都四小牛科技有限公司</p>
          <a href="https://beian.miit.gov.cn/">蜀ICP备2021025007号</a>
        </van-col>
      </van-row>
      <div style="padding: 10px;"></div>
    </div>
    <div style="position: fixed;top: 35vh;right: 30px;" class="shadow mcenter">
      <van-image :src="require('@/assets/sixu2.png')" height="135px" width="135px"></van-image>
      <p class="mcenter font14">联系我们</p>
      <van-image :src="require('@/assets/sixu.jpg')" height="150px" width="150px"></van-image>
      <p class="mcenter font14">关注公众号</p>
    </div>
<!--
    <div style="position: fixed;bottom: 2vh;right: 20px;">

    </div>
-->

  </div>
</template>

<script>
export default {
  name: "newhome",
}
</script>

<style scoped>
.w98{width: 98vw;}
.mcenter{text-align: center;}
.mt10{margin-top: 10px;}
.font14{font-size: 14px;}
.ml10{margin-left: 10px;}
.shadow{padding: 15px 10px;box-shadow: 0 0 5px #DCDCDC;background: #fff;}
</style>